import React, { useRef } from "react";
import { Grid } from "react-bootstrap";
import { withRouter, useHistory } from "react-router-dom";
import { isAuthenticate, isLoggedIn } from "../_helper/authentication";
import Header from "./header/Header";
import { Sidebar, subscribePopup } from "./sidebar/Sidebar";
import Footer from "./Footer";
import Modal from "./sidebar/Modal";
import IdleTimer from "react-idle-timer";
import Swal from "sweetalert2";

var popUp = subscribePopup;

const Template = ({ children }) => {
  console.log("subscribeStatus is " + subscribePopup);
  const [popup, setPopup] = React.useState(subscribePopup);
  const [show, setShow] = React.useState(false);
  const [idle, setIdle] = React.useState(false);
  const idleTimerRef = useRef(null);
  const history = useHistory();

  console.log("popup is " + popUp);
  const showSidebar = () => {
    setShow(true);
    document.getElementsByTagName("html")[0].style.overflowY = "hidden";
  };
  const hideSidebar = () => {
    setShow(false);
    document.getElementsByTagName("html")[0].style.overflowY = "scroll";
  };
  const onIdle = () => {
    console.log("user not logged in");
    console.log(isAuthenticate());
    if (isAuthenticate() === true) {
      localStorage.removeItem("userDetails");
      localStorage.removeItem("JWT");
      Swal.fire({
        type: "info",
        title: "You have been logged out",
      });
      history.push("/");
    }
  };

  const onActive = (e) => {
    setIdle(false);
    console.log("user become active", e);
  };

  //   const { reset,getElapsedTime } = useIdleTimer({ onIdle, onActive,  timeout: 1000 * 5, })

  return (
    <React.Fragment>
      <IdleTimer
        ref={idleTimerRef}
        element={document}
        onActive={onActive}
        onIdle={onIdle}
        timeout={1000 * 60 * 30}
      >
        <Header
          openSideBar={showSidebar}
          isAuthenticate={Boolean(isAuthenticate())}
        />
        <div className="clearfix"></div>
        <Sidebar open={show} closeSideBar={hideSidebar} />

        {/* <Modal closeModal={() => setPopup(false)}><p>hello worls</p></Modal> */}

        {/* <div>Hello</div> */}

        <Grid fluid={true} className="page-content">
          {children}
        </Grid>
        <Footer />
      </IdleTimer>
    </React.Fragment>
  );
};

export default Template;
