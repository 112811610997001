import React from 'react';
import { Link } from 'react-router-dom';
import goalyLogo from '../../assets/img/football-avenger-logo-1.png';
import cellcardLogo from '../../assets/img/slider/new_cellcard_logo.png'
import Ionicon from 'react-ionicons';
import './header.scss';


const Header = React.memo(({ isAuthenticate, openSideBar }) => (
	<div id="toolbar" className="black">
		<div className="logo">
		    <Link to='/'><img src={goalyLogo} height="64" alt=""/></Link>
			{/* <Link to='/'><img src={goalyLogo} height="64" alt="" style={{ height:"50px" , width: "119px" , marginTop: "10px" }}/></Link>
			<div style={{ background:"white" , width:"1px" , height:"36px",marginTop:"15px" }}></div>
			<img src={cellcardLogo} height="64" alt="" style={{ height:"50px", width: "139px", marginTop: "10px" }}></img> */}
		</div>
		 {/* {screen.width!=320 &&  */}
			<div className="m-0" style={{margin:'auto'}}>&nbsp;</div>
		{/* }  */}
			
		<div className="open-right">
			{isAuthenticate ? <AuthenticatedMenu /> : <NotAuthenticatedMenu />}
			<button onClick={openSideBar} type="button" className="button-collapse navbar-toggle nav-toggle">
				<span className="sr-only">Toggle navigation</span>
				<span className="icon-bar white"></span>
				<span className="icon-bar white spec"></span>
				<span className="icon-bar white"></span>
			</button>
		</div>
	</div>
));

export default Header;

const AuthenticatedMenu = React.memo(() => (
	<React.Fragment>
		<Link to="/search" className="ion-head">
			<Ionicon icon="md-search" className="hydrated" fontSize="32px" color="#fff" />
		</Link>
		<Link to='/profile' className="ion-head">
			<Ionicon icon="md-person" className="hydrated" fontSize="32px" color="#fff" />
		</Link>
	</React.Fragment>
));

const NotAuthenticatedMenu = React.memo(() => (
	<Link to="/search" className="ion-head">
		<Ionicon icon="md-search" className="hydrated" fontSize="32px" color="#fff" />
	</Link>
));