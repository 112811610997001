import React from 'react';
import './modal.css';
import FontAwesome from 'react-fontawesome';
import { withRouter } from 'react-router';
import { setUserDetails,getUserDetails } from '../../_helper/authentication';
import axios from '../../_config/axios';

const Modal = (props) => {
  const { closeModal } = props;
  const [msisdn,setmsisdn]= React.useState("");
  const [checkMsisdn,setCheckMsisdn]= React.useState(false);

  const closeicon = () => (
    <FontAwesome
    name="times"
    onClick={closeModal}
    style={{
      color: '#000000',
      padding: '10px',
      cursor: 'pointer',
      backgroundColor: 'transparent',
      border: 0,
      position: 'absolute',
      top: '0.3rem',
      right: '0.5rem',
    }}
    />
  );

  // const dailysub = () =>{
  //   console.log("hi")
  //   console.log(msisdn)
  //   const payload = new FormData();
  //   payload.append('msisdn', msisdn);
  //   payload.append('username', getUserDetails().username);
  //   axios.post('Subscription/unsub_refcode', payload)
  //       .then(res => {
  //           if (res.status= '200'){

  //             localStorage.clear();
  //             window.location.reload("/");

  //           }
  //       })
  //       .catch(err => console.log(err));

  // }

  const dailysub = () =>{
    console.log("hi")
    console.log(msisdn)

      const payload = new FormData();
      payload.append('phone_no', msisdn);
      axios.post('StageGoalyApi/check_login_status', payload)
          .then(res => {
              console.log(res)
              if(res.data.status == '200'){
                  if (res.data.data.user_details.status== 'active'){
                          
                      setCheckMsisdn(true);
                  }else{

                    props.history.push({
                      pathname: `Package/${msisdn}`,
                      state: { msisdn: msisdn }
                    })
                    window.location.reload();
                  }
              }else{

                  props.history.push({
                      pathname: `Package/${msisdn}`,
                      state: { msisdn: msisdn }
                    })
                    window.location.reload();
              }
          })
          .catch(err => console.log(err));   
  }


  const handlechange =(e)=>{
      setmsisdn(e.target.value)
  }

  


  return (
    // <div className="overlay">
    //   <div className="content" style={{top:192,minWidth:'-webkit-fill-available',height:176}}>
    //     { closeicon() }
    //     {/* {props.children} */}
    //     <div style={{marginTop:12}}>Enter Msisdn</div><br/>
    //     <input type='text' style={{textAlign:'center',borderRadius:81,width:'100%',height:'35px'}} onChange={handlechange} placeholder='96XXXXXXX'></input>
    //     {checkMsisdn && <div style={{color:'green'}}>Phone number already subcscribe</div> }

    //     <button style={{marginTop:10,backgroundColor:'red'}} onClick={dailysub}>Submit</button>

    //   </div>
    // </div>

    <div className="overlay">
      {/* <div className="content" style={{top:192,minWidth:'-webkit-fill-available',height:176}}>
        { closeicon() } */}
        {/* {props.children} */}
        {/* <div style={{marginTop:12,fontSize:20}}>Please choose your subscription type!</div><br/> */}

        {/* <button style={{marginTop:10,marginRight:3,backgroundColor:'dodgerblue'}}>Daily</button>
        <button style={{marginTop:10,marginLeft:3,backgroundColor:'dodgerblue'}}>Weekly</button> */}

      {/* </div> */}
     
      <div className="wrapper">
        {/* <!-- header image ----------------------------------------> */}
        {/* <img src="./assets/img/header-fa.png" className="img-fluid" alt=""/> */}
        {/* <!-- header image ----------------------------------------> */}
        <div className="container-fluid">
            <div className="text-center">
                <h4 className="mt-2 mb-2"><strong>Choose Subscribe</strong></h4>
            </div>
            <div className="alert text-center" style={{backgroundColor: "#E5E5E5"}}>Subscribe to compete with others and get the prize</div>
            <div className="mb-2">
                <label className="btn radio-subscribe btn-default d-block text-left">
                    {/* <input className="" type="radio" name="options" id="option1"> Daily Subscription */}
                    <span className="float-right"><strong>100</strong>/day</span>
                </label>
            </div>
            <div className="mb-2">
                <label className="btn radio-subscribe btn-default d-block text-left">
                    {/* <input className="" type="radio" name="options" id="option2"/> Weekly Subscription */}
                    <span className="float-right"><strong>600</strong>/Week</span>
                </label>
            </div>
            <button type="button" className="btn bg-orange p-1 w-100 my-2 text-white shadow" style={{fontSize: "12pt"}}><strong>Subscribe</strong></button>
            <p className="text-center">Please be noted that this service is auto renewal.</p>
        </div>
    </div>
    </div>

    
  );
};


export default withRouter(Modal);